$(function(){
	$('.filter__select').on('click', function(e) {
		let $currentSelect = $(this);

		if($currentSelect.hasClass("filter__select--active")) {
			$currentSelect.next(".filter__selectBody").fadeOut(300);
			$currentSelect.removeClass("filter__select--active");
			return;
		}

		$(".filter__selectBody").fadeOut(300);
		$('.filter__select').removeClass("filter__select--active");

		$currentSelect.next(".filter__selectBody").fadeIn(300);
		$currentSelect.addClass("filter__select--active");

		$("body").on("click", { current: $currentSelect }, closeSelect);
	});

	function closeSelect(e) {
		if(e.target.closest(".filter__item") === null) {
			e.data.current.next(".filter__selectBody").fadeOut(300);
			e.data.current.removeClass("filter__select--active");

			$("body").off("click", closeSelect);
		}
	}

	$("input[name='v-salary-option']").on("change", function(e) {
		if(e.target.value === "salary") {
			$(".filter__currency, .filter__number, .filter__salaryRange").prop("disabled", false);
		}
		else {
			$(".filter__currency, .filter__number, .filter__salaryRange").prop("disabled", true);
		}
	});

	$("input[name='v-age-option']").on("change", function(e) {
		if(e.target.value === "salary") {
			$(".filter__ageNumber, .filter__ageRange").prop("disabled", false);
		}
		else {
			$(".filter__ageNumber, .filter__ageRange").prop("disabled", true);
		}
	})

	const currencyLimits = {
		czk: {
			min: 120,
			max: 220,
			step: 5
		},
		eur: {
			min: 5,
			max: 10,
			step: 0.1
		}
	}

	let currentCurrency = "czk";

	$(".filter__currency").on("change", function(e) {
		currentCurrency = e.target.value;

		$(".filter__salaryRange, .filter__number").attr(currencyLimits[currentCurrency]);
		$(".filter__salaryRange, .filter__number").val(currencyLimits[currentCurrency].min);

	})

	$(".filter__salaryRange").on("input", function(e) {
		$(".filter__number").val(e.target.value)
	});

	$(".filter__number").on("input", function(e) {
		let value = e.target.value;

		if(value < currencyLimits[currentCurrency].min) {
			value = currencyLimits[currentCurrency].min;
		}
		if(value > currencyLimits[currentCurrency].max) {
			value = currencyLimits[currentCurrency].max;
		}

		e.target.value = value;
		$(".filter__salaryRange").val(value);
	});

	$(".filter__ageRange").on("input", function(e) {
		$(".filter__ageNumber").val(e.target.value)
	});

	const ageLimits = {
		min: 16,
		max: 65
	}

	$(".filter__ageNumber").on("change", function(e) {
		let value = e.target.value;

		if(value < ageLimits.min) {
			value = ageLimits.min;
		}
		if(value > ageLimits.max) {
			value = ageLimits.max;
		}

		e.target.value = value;
		$(".filter__ageRange").val(value);
	});

	// window.addEventListener("scroll", function(e) {
	// 	$(".filter__selectBody").fadeOut(300);
	// 	$('.filter__select').removeClass("filter__select--active");
	// });

	let vakancyList = document.querySelector(".vakancy__list");
	let vakancies = document.querySelectorAll(".vakancy__item");
	let rootVakancies = [...vakancies];

	$(".filter__data").on("change", function(e) {
		let filteredQuery = $(".filter").serialize();

		filteredQuery = filteredQuery.split("&").map(q => {
			let newQ = q.replace("v-", "v");
			newQ = newQ.split("");
			newQ[1] = newQ[1].toUpperCase();
			newQ = newQ.join("");

			newQ = newQ.split("=");

			return {
				field: newQ[0],
				value: newQ[1]
			}
		})

		let rawFilteredVakancies = [];
		let filteredVakancies = [];

		let isActiveSalary = false;
		let isActiveAge = false;
		filteredQuery.forEach(q => {
			if(q.value === "salary") {
				isActiveSalary = true;
			}
			if(q.field === "vAge") {
				isActiveAge = true;
			}
		});

		if(isActiveSalary) {
			rootVakancies.forEach(vakancy => {
				let data = vakancy.dataset;
				let vakancySalary = 0;

				filteredQuery.forEach(q => {
					if(q.field === "vCurr") {
						vakancySalary = parseInt(
							vakancy.querySelector(`.vakancy__salary--${q.value} span`).innerText
						) ?? 0;
					}
					else if(q.field === "vSalary") {
						if(q.value <= vakancySalary) {
							rawFilteredVakancies.push(vakancy);
							return;
						}
					}
				})
			});
		}
		else {
			rawFilteredVakancies = rootVakancies;
		}

		if(isActiveAge) {
			let tempFiltered = [];

			rawFilteredVakancies.forEach(vakancy => {
				let data = vakancy.dataset;

				filteredQuery.forEach(q => {
					if(q.field === "vAge") {
						if(data[q.field] >= q.value) {
							tempFiltered.push(vakancy);
						}
					}
				});
			});

			rawFilteredVakancies = tempFiltered;
		}

		if(!isActiveSalary && !isActiveAge && filteredQuery.length === 2) {
			filteredVakancies = rawFilteredVakancies;
		}
		else if(isActiveSalary && filteredQuery.length === 4) {
			filteredVakancies = rawFilteredVakancies;
		}
		else if(isActiveAge && filteredQuery.length === 3) {
			filteredVakancies = rawFilteredVakancies;
		}
		else if((isActiveSalary && isActiveAge) && filteredQuery.length === 5) {
			filteredVakancies = rawFilteredVakancies;
		}
		else {
			rawFilteredVakancies.forEach(vakancy => {
				let data = vakancy.dataset;

				filteredQuery.forEach(q => {
					if(!!data[q.field]) {
						if(
							q.value === data[q.field] ||
							data[q.field].includes(q.value)
						) {
							filteredVakancies.push(vakancy);
							return;
						}
					}
				});
			});
		}

		if(filteredVakancies.length === 0) {
			$(".vakancy__list").addClass("vakancy__list--empty");
		}
		else {
			$(".vakancy__list").removeClass("vakancy__list--empty");
		}

		vakancyList.replaceChildren();
		filteredVakancies.forEach(node => $('.vakancy__list').append(node));
	});

	$(".filter__clear").on("click", function(e) {
		e.preventDefault();

		$(".filter").trigger("reset");
		$(".vakancy__list").removeClass("vakancy__list--empty");
		rootVakancies.forEach(node => $('.vakancy__list').append(node));
	});

	$('.review__textList').slick({
		slidesToShow: 3,
		slidesToScroll: 1,
		prevArrow: '.review__textBtn--prev',
		nextArrow: '.review__textBtn--next',
		responsive: [{
			breakpoint: 969,
			settings: {
				slidesToShow: 2
			}
		},{
			breakpoint: 600,
			settings: {
				slidesToShow: 1,
			}
		}
		]
	});

	$('.docs__list').slick({
		slidesToShow: 3,
		slidesToScroll: 1,
		arrows: false,
		responsive: [{
			breakpoint: 768,
			settings: {
				slidesToShow: 2
			}
		},{
			breakpoint: 600,
			settings: {
				slidesToShow: 1,
			}
		}
		]
	});

	/*

	let $lang 	= $('body').data('lang');
	let clWidth = document.documentElement.clientWidth;
	let offset = window.pageYOffset;

	function checkOffset() {
		offset = window.pageYOffset;
		let head = $('.header__head');

		if($('header').hasClass('header--main')) {
			if (offset > 550) {
			  $('body').css('marginTop', '0px');
			  head.addClass('header__head--fixed');
			} else {
			  $('body').css('marginTop', '0px');
			  head.removeClass('header__head--fixed');
			}
		}
	}
	
	$('.getConsultation').on('click', function() {
		$('.popup--consultation').show(100, function() {
			$('.popup--consultation').css('opacity', '1');
			$('body').addClass('popup-open');
		});
	});

	$('.popup__close').on('click', function() {
		$('.popup').css('opacity', '0');
		$('body').removeClass('popup-open');

		setTimeout(function() {
			$('.popup').hide();
		});
	});

	$('.nav__bar').on('click', function() {
		let nav = $(this).closest('.nav')
		nav.addClass('nav--open');
		$('body').addClass('popup-open');
	});

	$('.nav__close, .nav__link').on('click', function() {
		$('.nav').removeClass('nav--open');
		$('body').removeClass('popup-open');
	});

	$('.nav__link, .footer__link').on('click', function(e) {

	let target = $(this).attr('href');
		if(clWidth < 769) {
			$('html, body').animate({
			  scrollTop: $(target).offset().top - 90
			}, 1500);
		} else {
			$('html, body').animate({
			  scrollTop: $(target).offset().top
			}, 1500);
		}
	});

	$('.vakancy__showMore').on('click', function() {
		let $btn = $(this);
		
		let $details = $btn.closest('.vakancy__item').find('.vakancy__details--general').slideToggle(500).toggleClass('vakancy__details--showed');

		if($details.hasClass('vakancy__details--showed')) {
			if($lang == 'ru') {
				$btn.text('Скрыть детали вакансии');
			} else {
				$btn.text('Приховати деталі вакансії');
			}
		} else {
			if($lang == 'ru') {
				$btn.text('Детали вакансии');
			} else {
				$btn.text('Деталі вакансії');
			}
		}
	});

	$('.vakancy__button').on('click', function() {
		let popup = $(this).parent().next();

		popup.show(100, function() {
			popup.css('opacity', '1');

			$('body').addClass('popup-open');
		});
	})

	$('.faq__item').on('click', function(e) {
		if($(e.target)[0] === $(this).children('p')[0]) {
        	return false;
        }
  		$(this).toggleClass('faq__item--active').children('p').slideToggle(300);
        $('.faq__item').not(this).removeClass('faq__item--active').children('p').slideUp(300);
  	});

	// checkOffset();

	// $(window).on('scroll', function() {
	// 	checkOffset();
	// });

	if(clWidth > 768) {
		let slider = $('.header__slider');
		let path = slider.data('src');

	  	slider.resizeSlider({
			"image":{
				"presentacion":{
				"landscape":{
					"url"      : `url(${path}/cs-bg.jpg)`,
					"size"     : "cover",
					"repeat"   : "no-repeat",
					"position" : "center top"
				},
					"portrait":{
						"url"      : `url(${path}/cs-bg.jpg)`,
						"size"     : "cover",
						"repeat"   : "no-repeat",
						"position" : "center"
					}
				},
				"presentacion 1":{
					"landscape":{
						"url"      : `url(${path}/cs-bg-2.jpg)`,
						"size"     : "cover",
						"repeat"   : "no-repeat",
						"position" : "center top"
					},
					"portrait":{
						"url"      : `url(${path}/cs-bg-2.jpg)`,
						"size"     : "cover",
						"repeat"   : "no-repeat",
						"position" : "center"
						}
				},
				"presentacion 2":{
					"landscape":{
						"url"      : `url(${path}/about-1.jpg)`,
						"size"     : "cover",
						"repeat"   : "no-repeat",
						"position" : "center top"
					},
					"portrait":{
						"url"      : `url(${path}/about-1.jpg)`,
						"size"     : "cover",
						"repeat"   : "no-repeat",
						"position" : "center"
					}
				},
				"presentacion 3":{
					"landscape":{
						"url"      : `url(${path}/about-2.jpg)`,
						"size"     : "cover",
						"repeat"   : "no-repeat",
						"position" : "center top"
					},
					"portrait":{
						"url"      : `url(${path}/about-2.jpg)`,
						"size"     : "cover",
						"repeat"   : "no-repeat",
						"position" : "center"
					}
				},
				"presentacion 3":{
					"landscape":{
						"url"      : `url(${path}/about-3.jpg)`,
						"size"     : "cover",
						"repeat"   : "no-repeat",
						"position" : "center top"
					},
					"portrait":{
						"url"      : `url(${path}/about-3.jpg)`,
						"size"     : "cover",
						"repeat"   : "no-repeat",
						"position" : "center"
					}
				}
			}
		});

		setTimeout(function() {
			let sliderHeight = slider.height();
			$('.header--vakancy').height(sliderHeight);
		});
	}

	$('.review__list').slick({
		slidesToShow: 3,
		slidesToScroll: 1,
		prevArrow: '.review__btn--prev',
		nextArrow: '.review__btn--next',
		responsive: [{
			breakpoint: 768,
			settings: {
				slidesToShow: 2
			}
		},{
			breakpoint: 600,
			settings: {
				slidesToShow: 1,
			}
		}
		]
	});

	$('.partner__list').slick({
		slidesToShow: 6,
		slidesToScroll: 1,
		dots: false,
		arrows: false,
		autoplay: true,
		infinite: true,
		speed: 700,
		responsive: [{
			breakpoint: 768,
			settings: {
				slidesToShow: 3,
			}
		},{
			breakpoint: 550,
			settings: {
				slidesToShow: 2,
			}
		}
		]
	});

	let $catData = $('.vakancy__categoryList').data();
	let $catList = $('.vakancy__categoryList--all .categories ul');
	$catList.attr('data-catlang', $catData.catlang);

	if($catData.status === 'single') {
		if($catData.lang === 'ru') {
			$catList.prepend("<li class='cat-item'><a aria-current'page' href='http://ceawork.cz/vakancy/'>Все</a></li>");
		} else if($catData.lang === 'ua') {
			$catList.prepend("<li class='cat-item'><a aria-current'page' href='http://ceawork.cz/ua/vakancy/'>Всi</a></li>");
		} else {
			$catList.prepend("<li class='cat-item'><a aria-current'page' href='http://ceawork.cz/cz/vakancy/'>Všechno</a></li>");
		}
	} else {
		if($catData.lang === 'ru') {
			$catList.prepend("<li class='cat-item current-cat'><a aria-current'page' href='http://ceawork.cz/vakancy/'>Все</a></li>");
		} else if($catData.lang === 'ua') {
			$catList.prepend("<li class='cat-item current-cat'><a aria-current'page' href='http://ceawork.cz/ua/vakancy/'>Всi</a></li>");
		} else {
			$catList.prepend("<li class='cat-item current-cat'><a aria-current'page' href='http://ceawork.cz/cz/vakancy/'>Všechno</a></li>");
		}
	}

	$('.review__play').on('click', function() {
		let $item = $(this).closest('.review__item');
		let $link = $item.data('yt').split('/').pop();

		$item.addClass('review__item--clicked').html(`
			<iframe width="100%" height="188" src="https://www.youtube.com/embed/${$link}" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
		`);
	});

	if(localStorage.getItem('cc-accept') == null) {
		setTimeout(function() {
			$('.cc_container').addClass('cc_container--open');
		}, 2000);
	  }

	  $('.cc_btn').on("click", function() {
	  	$('.cc').addClass('cc--closed');

	  	localStorage.setItem('cc-accept', 1);
	  });

	if($('header').hasClass('header--main')) {
		if(clWidth < 1025) {
			$('.promForm__submit').data().status = 'showForm';
		} else {
			$('.promForm__submit').data().status = 'send';
		}
	}

	$('.promForm__submit').on('click', function(e) {
		let $submit = $(this);
		let $form = $('.promForm__form');
		let $path   = $form.data('src');
		let $errors = $('.promForm__item[data-error]');

		if($submit.data().status === 'send') {

			$submit.attr('disabled', true);

			$.ajax({
	            url: `${$path}/php/send-prom.php`,
	            method: 'POST',
	            data: $form.serialize(),
	            dataType: 'json',
	            timeout: 10000,
	            success: onSuccess,
	            error: function() {
	                // $result.html('Превышено ожидание ответа от сервера...');
	            },
	            complete: function() {
	                $submit.attr('disabled', false);
	                // console.log($form.serialize());
	            },
	        });

	        function onSuccess(data) {
	            if(data.res) {
	            	$('.promForm__item').removeClass('promForm__item--wrong');
	               
	               let link = '';

					if( $lang == 'ua') {
			        	link = 'https://www.ceawork.cz/thanks/';
			        } else if( $lang == 'ru') {
			        	link = 'https://www.ceawork.cz/ru/thanks/';
			        }

					let a = document.createElement("a");
					a.setAttribute('href', link);
					a.click();

	            } else {
	                $errors.attr('data-error', '');
	                $('.promForm__item').removeClass('promForm__item--wrong');

	                for( let name in data.errors) {
	                    let target = $(`[name=${name}]`);
	                    
	                    if(target.length > 0){
	                        target.closest($errors).attr('data-error', data.errors[name]);
	                        target.parent().addClass('promForm__item--wrong');
	                    }
	                }
	            }
	        }
		} else {
			$('.promForm__wrapper').slideDown(300);
			$submit.data().status = 'send';
		}
	});

    $('.form__button--cs').on('click', function() {
		let $submit = $(this);
		let $form   = $('.form--cs');
		let $path   = $form.data('src');
		let $result = $('.form__result--cs');
		let $errors = $('.form__input[data-error]');
      
       $submit.attr('disabled', true);

        $.ajax({
            url: `${$path}/php/send.php`,
            method: 'POST',
            data: $form.serialize(),
            dataType: 'json',
            timeout: 10000,
            success: onSuccess,
            error: function() {
                // $result.html('Превышено ожидание ответа от сервера...');
            },
            complete: function() {
                $submit.attr('disabled', false);
                // console.log($form.serialize());
            },
        });

        function onSuccess(data) {
            if(data.res) {
            	$('.form__input').removeClass('form__input--wrong');
               
               let link = '';

				if( $lang == 'ru') {
		        	link = 'https://www.ceawork.cz/thanks/';
		        } else if( $lang == 'ua') {
		        	link = 'https://www.ceawork.cz/ua/thanks/';
		        }

				let a = document.createElement("a");
				a.setAttribute('href', link);
				a.click();

            } else {
                $errors.attr('data-error', '');
                $('.form__input').removeClass('form__input--wrong');

                for( let name in data.errors) {
                    let target = $(`[name=${name}]`);
                    
                    if(target.length > 0){
                        target.closest($errors).attr('data-error', data.errors[name]);
                        target.parent().addClass('form__input--wrong');
                    }
                }
            }
        }
    });

    $('.form__button--cs-2').on('click', function() {
		let $submit = $(this);
		let $form   = $('.contact__form');
		let $path   = $form.data('src');
		let $result = $('.form__result--footer');
		let $errors = $('.form__input[data-error]');
      
       $submit.attr('disabled', true);

        $.ajax({
            url: `${$path}/php/send-footer.php`,
            method: 'POST',
            data: $form.serialize(),
            dataType: 'json',
            timeout: 10000,
            success: onSuccess,
            error: function() {
                // $result.html('Превышено ожидание ответа от сервера...');
            },
            complete: function() {
                $submit.attr('disabled', false);
                // console.log($form.serialize());
            },
        });

        function onSuccess(data) {
            if(data.res) {
            	$('.form__input').removeClass('form__input--wrong');
              	
              	let link = '';

				if( $lang == 'ru') {
		        	link = 'https://www.ceawork.cz/thanks/';
		        } else if( $lang == 'ua') {
		        	link = 'https://www.ceawork.cz/ua/thanks/';
		        }

				let a = document.createElement("a");
				a.setAttribute('href', link);
				a.click();

            } else {
                $errors.attr('data-error', '');
                $('.form__input').removeClass('form__input--wrong');

                for( let name in data.errors) {
                    let target = $(`[name=${name}]`);
                    
                    if(target.length > 0){
                        target.closest($errors).attr('data-error', data.errors[name]);
                        target.parent().addClass('form__input--wrong');
                    }
                }
            }
        }
    });

    $('.form__button--vakancy').on('click', function() {
		let $submit = $(this);
		let $form   = $(this).closest('.vakancy__form');
		let $path   = $form.data('src');
		let $result = $form.next();
		let $errors = $form.children('.form__input[data-error]');
       	
       	$submit.attr('disabled', true);

        $.ajax({
            url: `${$path}/php/send-vakancy.php`,
            method: 'POST',
            data: $form.serialize(),
            dataType: 'json',
            timeout: 10000,
            success: onSuccess,
            error: function() {
                // $result.html('Превышено ожидание ответа от сервера...');
            },
            complete: function() {
                $submit.attr('disabled', false);
                // console.log($form.serialize());
            },
        });

        function onSuccess(data) {
            if(data.res) {
            	$('.form__input').removeClass('form__input--wrong');

				let link = '';

				if( $lang == 'ru') {
		        	link = 'https://www.ceawork.cz/thanks/';
		        } else if( $lang == 'ua') {
		        	link = 'https://www.ceawork.cz/ua/thanks/';
		        }

				let a = document.createElement("a");
				a.setAttribute('href', link);
				a.click();
                
            } else {
                $errors.attr('data-error', '');
                $('.form__input').removeClass('form__input--wrong');

                for( let name in data.errors) {
                    let target = $(`[name=${name}]`);
                    
                    if(target.length > 0){
                        target.closest($errors).attr('data-error', data.errors[name]);
                        target.parent().addClass('form__input--wrong');
                    }
                }
            }
        }
    });

    $.fn.animated = function(inEffect) {
		$(this).each(function() {
			var ths = $(this);
			ths.css("opacity", "0").addClass("animated").waypoint(function(dir) {
				if (dir === "down") {
					ths.addClass(inEffect).css("opacity", "1");
				};
			}, {
				offset: "70%"
			});

		});
	};

	$(".fadeLeftAnim").animated("animate__fadeInLeft");
	$(".fadeRightAnim").animated("animate__fadeInRight");

	$('.fadeUpAnim').animated("animate__fadeInUp");

	*/
});